import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { Button } from '@meetup/swarm-components';
import { Icon } from '@meetup/swarm-components';
import Example from '../../components/examples/button';
import PropsTable from '../../components/propstable';
import { Close, Check } from '@meetup/swarm-icons/lib/components/solid';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Button`}</h1>
    <hr></hr>
    <p>{`Buttons are a fundamental component and primarily how users trigger actions. They can be customized by sizes and color to communicate importance and content heirarchy.`}</p>
    <br /><br />
    <Example mdxType="Example" /><br />
    <br /><br />
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<>
  <Button>Default</Button>
  <Button primary>Primary</Button>
  <Button neutral>Neutral</Button>
  <Button bordered>Bordered</Button>
  <Button icon="send">Send message</Button>
  <Button large iconOnly>
    <Search/>
  </Button>
  <br/><br/>
  <Button neutral grow icon="InfoCircled">Fill the space</Button>
</>
`}</code></pre>
    <br /><br />
    <h3>{`Props`}</h3>
    <br />
    <PropsTable propMetaData={Button.__docgenInfo} mdxType="PropsTable" />
    <br /><br />
    <h3>{`Anatomy`}</h3>
    <p>{`All button styles and sizes share the same anatomy.`}</p>
    <br />
    <img src="/docs-images/button-anatomy.png" width="810" height="252" />
    <br />
    <div className="grid-2-col">
    <div className="column">
        <p className="text--body"><b>Label</b></p>
        <p className="text--body">Button labels should be short and actionable. Label attributes are driven by the selected button style and size.</p>
    </div>
    <div className="column">
        <p className="text--body"><strong>Border</strong></p>
        <p className="text--body">All buttons have a solid 1px border, though some styles require the same color for background and border, hiding the presence of the border.</p>
    </div>
    <div className="column">
        <p className="text--body"><strong>Background</strong></p>
        <p className="text--body">Button style determines its background color and opacity.</p>
    </div>
    <div className="column">
        <p className="text--body"><strong>Radius</strong></p>
        <p className="text--body">All buttons in all sizes and viewports have an 8px border radius.</p>
    </div>
    </div>
    <br /><br />
    <h3>{`Sizing`}</h3>
    <br />
    <p><strong parentName="p">{` Size & Height `}</strong></p>
    <p>{`Any button can be configured to one of three distinct sizes. Size dictates label text style and top/bottom padding. Button padding uses spacing variables which are dynamic based on viewport.`}</p>
    <br />
    <img src="/docs-images/button-sizing-size.png" width="600" height="405" />
    <br /><br /><br />
    <p><strong parentName="p">{` Width `}</strong></p>
    <p>{`Buttons will inherit their left and right padding from their size. Use the grow attribute to expand a button to fill its container.`}</p>
    <br />
    <img src="/docs-images/button-sizing-width.png" width="600" height="120" />
    <br /><br /><br /><br />
    <h3>{`a11y`}</h3>
    <br />
    <p><strong parentName="p">{` Keyboard interactions`}</strong></p>
    <p>{`The button action should be triggered by the enter and space keys.`}</p>
    <br /><br /><br />
    <h3>{`Content Guidelines`}</h3>
    <br />
    <p><strong parentName="p">{` Button labels `}</strong></p>
    <p>{`Should clearly indicate what will happen if the user clicks on them. They should be easy to scan and comprehend.`}</p>
    <br />
    <ul style={{
      "margin": "0 0 0 20px"
    }}>
    <li> Keep it scannable: Avoid articles like "a" and "the"</li>
    <li> Use sentence case</li>
    <li> Don't use first person to speak from the user's point of view (Sign up vs. Sign me up!)</li>
    <li> No ampersands or end punctuation (Agree and continue vs. Agree & continue)</li>
    <li> Make priorities clear: Never present the user with more than one primary action</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      